import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Lost = () =>
  <svg viewBox="0 0 974 503" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M277.676 125.876H176.464V127.324H277.676V125.876Z" fill="#F2F2F2" />
    <path d="M205.381 112.848H203.936V126.238H205.381V112.848Z" fill="#F2F2F2" />
    <path d="M249.481 112.848H248.035V126.238H249.481V112.848Z" fill="#F2F2F2" />
    <path d="M242.975 53.4951H141.763V54.9427H242.975V53.4951Z" fill="#F2F2F2" />
    <path d="M215.503 54.5806H214.057V67.971H215.503V54.5806Z" fill="#F2F2F2" />
    <path d="M171.403 54.5806H169.958V67.971H171.403V54.5806Z" fill="#F2F2F2" />
    <path d="M403.467 154.828H302.255V156.276H403.467V154.828Z" fill="#F2F2F2" />
    <path d="M331.173 141.8H329.728V155.19H331.173V141.8Z" fill="#F2F2F2" />
    <path d="M375.273 141.8H373.827V155.19H375.273V141.8Z" fill="#F2F2F2" />
    <path d="M368.766 82.4478H267.554V83.8954H368.766V82.4478Z" fill="#F2F2F2" />
    <path d="M341.295 83.5332H339.849V96.9237H341.295V83.5332Z" fill="#F2F2F2" />
    <path d="M297.195 83.5332H295.749V96.9237H297.195V83.5332Z" fill="#F2F2F2" />
    <path d="M363.706 400.923H262.494V402.371H363.706V400.923Z" fill="#F2F2F2" />
    <path d="M291.411 387.895H289.965V401.285H291.411V387.895Z" fill="#F2F2F2" />
    <path d="M335.511 387.895H334.065V401.285H335.511V387.895Z" fill="#F2F2F2" />
    <path d="M329.005 328.542H227.793V329.99H329.005V328.542Z" fill="#F2F2F2" />
    <path d="M301.533 329.628H300.087V343.019H301.533V329.628Z" fill="#F2F2F2" />
    <path d="M257.434 329.628H255.988V343.019H257.434V329.628Z" fill="#F2F2F2" />
    <path d="M317.437 244.581H216.226V246.028H317.437V244.581Z" fill="#F2F2F2" />
    <path d="M245.144 231.552H243.698V244.943H245.144V231.552Z" fill="#F2F2F2" />
    <path d="M289.243 231.552H287.797V244.943H289.243V231.552Z" fill="#F2F2F2" />
    <path d="M282.736 172.2H181.524V173.647H282.736V172.2Z" fill="#F2F2F2" />
    <path d="M255.265 173.286H253.819V186.676H255.265V173.286Z" fill="#F2F2F2" />
    <path d="M211.165 173.286H209.719V186.676H211.165V173.286Z" fill="#F2F2F2" />
    <path d="M244.42 354.599H143.208V356.047H244.42V354.599Z" fill="#F2F2F2" />
    <path d="M172.127 341.571H170.681V354.961H172.127V341.571Z" fill="#F2F2F2" />
    <path d="M216.225 341.571H214.779V354.961H216.225V341.571Z" fill="#F2F2F2" />
    <path d="M209.719 282.219H108.507V283.666H209.719V282.219Z" fill="#F2F2F2" />
    <path d="M182.248 283.304H180.802V296.695H182.248V283.304Z" fill="#F2F2F2" />
    <path d="M138.148 283.304H136.702V296.695H138.148V283.304Z" fill="#F2F2F2" />
    <path d="M748.311 125.876H647.099V127.324H748.311V125.876Z" fill="#F2F2F2" />
    <path d="M676.016 112.848H674.57V126.238H676.016V112.848Z" fill="#F2F2F2" />
    <path d="M720.116 112.848H718.67V126.238H720.116V112.848Z" fill="#F2F2F2" />
    <path d="M713.609 53.4951H612.397V54.9427H713.609V53.4951Z" fill="#F2F2F2" />
    <path d="M686.138 54.5806H684.692V67.971H686.138V54.5806Z" fill="#F2F2F2" />
    <path d="M642.038 54.5806H640.592V67.971H642.038V54.5806Z" fill="#F2F2F2" />
    <path d="M874.102 154.828H772.891V156.276H874.102V154.828Z" fill="#F2F2F2" />
    <path d="M801.808 141.8H800.362V155.19H801.808V141.8Z" fill="#F2F2F2" />
    <path d="M845.908 141.8H844.462V155.19H845.908V141.8Z" fill="#F2F2F2" />
    <path d="M839.401 82.4478H738.189V83.8954H839.401V82.4478Z" fill="#F2F2F2" />
    <path d="M811.93 83.5332H810.484V96.9237H811.93V83.5332Z" fill="#F2F2F2" />
    <path d="M767.83 83.5332H766.384V96.9237H767.83V83.5332Z" fill="#F2F2F2" />
    <path d="M834.341 400.923H733.129V402.371H834.341V400.923Z" fill="#F2F2F2" />
    <path d="M762.047 387.895H760.601V401.285H762.047V387.895Z" fill="#F2F2F2" />
    <path d="M806.146 387.895H804.7V401.285H806.146V387.895Z" fill="#F2F2F2" />
    <path d="M799.639 328.542H698.427V329.99H799.639V328.542Z" fill="#F2F2F2" />
    <path d="M772.167 329.628H770.722V343.019H772.167V329.628Z" fill="#F2F2F2" />
    <path d="M728.068 329.628H726.622V343.019H728.068V329.628Z" fill="#F2F2F2" />
    <path d="M788.073 244.581H686.861V246.028H788.073V244.581Z" fill="#F2F2F2" />
    <path d="M715.778 231.552H714.333V244.943H715.778V231.552Z" fill="#F2F2F2" />
    <path d="M759.878 231.552H758.432V244.943H759.878V231.552Z" fill="#F2F2F2" />
    <path d="M753.371 172.2H652.16V173.647H753.371V172.2Z" fill="#F2F2F2" />
    <path d="M725.9 173.286H724.454V186.676H725.9V173.286Z" fill="#F2F2F2" />
    <path d="M681.8 173.286H680.354V186.676H681.8V173.286Z" fill="#F2F2F2" />
    <path d="M715.055 354.599H613.843V356.047H715.055V354.599Z" fill="#F2F2F2" />
    <path d="M642.761 341.571H641.315V354.961H642.761V341.571Z" fill="#F2F2F2" />
    <path d="M686.861 341.571H685.415V354.961H686.861V341.571Z" fill="#F2F2F2" />
    <path d="M680.354 282.219H579.143V283.666H680.354V282.219Z" fill="#F2F2F2" />
    <path d="M652.882 283.304H651.437V296.695H652.882V283.304Z" fill="#F2F2F2" />
    <path d="M608.783 283.304H607.337V296.695H608.783V283.304Z" fill="#F2F2F2" />
    <path d="M462.93 0H322.318V199.771H462.93V0Z" fill="#272B2A" />
    <path d="M643.304 239.581H502.692V439.352H643.304V239.581Z" fill="#272B2A" />
    <path d="M643.304 0H502.692V199.771H643.304V0Z" fill="#272B2A" />
    <path d="M462.93 239.581H322.318V439.352H462.93V239.581Z" fill="#272B2A" />
    <path d="M825.486 438.723H128.925V440.345H825.486V438.723Z" fill="black" />
    <path d="M142.306 427.771H134.354V495.085C134.354 495.085 185.682 511.732 235.565 495.085V427.771H142.306Z" fill="#272B2A" />
    <path d="M184.236 435.733C211.786 435.733 234.119 432.331 234.119 428.133C234.119 423.936 211.786 420.533 184.236 420.533C156.687 420.533 134.354 423.936 134.354 428.133C134.354 432.331 156.687 435.733 184.236 435.733Z" fill="#272B2A" />
    <path opacity="0.1" d="M184.236 435.733C211.786 435.733 234.119 432.331 234.119 428.133C234.119 423.936 211.786 420.533 184.236 420.533C156.687 420.533 134.354 423.936 134.354 428.133C134.354 432.331 156.687 435.733 184.236 435.733Z" fill="black" />
    <path d="M242.222 347.346C229.638 393.919 186.536 422.763 186.536 422.763C186.536 422.763 163.836 376.097 176.42 329.524C189.004 282.95 232.106 254.106 232.106 254.106C232.106 254.106 254.806 300.773 242.222 347.346Z" fill="#272B2A" />
    <path d="M127.744 347.346C140.328 393.919 183.431 422.763 183.431 422.763C183.431 422.763 206.131 376.097 193.547 329.524C180.963 282.95 137.861 254.106 137.861 254.106C137.861 254.106 115.161 300.773 127.744 347.346Z" fill="#BFC5C4" />
    <path d="M146.236 337.337C180.766 370.991 184.983 422.725 184.983 422.725C184.983 422.725 133.217 419.895 98.6863 386.241C64.1561 352.587 59.9385 300.853 59.9385 300.853C59.9385 300.853 111.705 303.682 146.236 337.337Z" fill="#272B2A" />
    <path d="M223.731 337.337C189.201 370.991 184.983 422.725 184.983 422.725C184.983 422.725 236.75 419.895 271.281 386.241C305.811 352.587 310.028 300.853 310.028 300.853C310.028 300.853 258.261 303.682 223.731 337.337Z" fill="#272B2A" />
    <path d="M184.959 269.98C193.743 269.98 200.864 262.851 200.864 254.057C200.864 245.262 193.743 238.133 184.959 238.133C176.175 238.133 169.055 245.262 169.055 254.057C169.055 262.851 176.175 269.98 184.959 269.98Z" fill="#BFC5C4" />
    <path d="M377.985 109.295C379.183 109.295 380.154 108.323 380.154 107.124C380.154 105.924 379.183 104.952 377.985 104.952C376.787 104.952 375.816 105.924 375.816 107.124C375.816 108.323 376.787 109.295 377.985 109.295Z" fill="white" />
    <path d="M367.864 81.0665C381.838 81.0665 393.167 69.7244 393.167 55.7332C393.167 41.742 381.838 30.3999 367.864 30.3999C353.89 30.3999 342.561 41.742 342.561 55.7332C342.561 69.7244 353.89 81.0665 367.864 81.0665Z" fill="white" />
    <path d="M408.348 91.1996C408.748 91.1996 409.071 90.8755 409.071 90.4758C409.071 90.076 408.748 89.752 408.348 89.752C407.949 89.752 407.625 90.076 407.625 90.4758C407.625 90.8755 407.949 91.1996 408.348 91.1996Z" fill="white" />
    <path d="M535.586 127.39C535.985 127.39 536.309 127.066 536.309 126.666C536.309 126.266 535.985 125.942 535.586 125.942C535.186 125.942 534.863 126.266 534.863 126.666C534.863 127.066 535.186 127.39 535.586 127.39Z" fill="white" />
    <path d="M527.634 343.809C528.033 343.809 528.357 343.485 528.357 343.085C528.357 342.685 528.033 342.361 527.634 342.361C527.235 342.361 526.911 342.685 526.911 343.085C526.911 343.485 527.235 343.809 527.634 343.809Z" fill="white" />
    <path d="M375.093 393.752C375.492 393.752 375.816 393.428 375.816 393.028C375.816 392.628 375.492 392.304 375.093 392.304C374.694 392.304 374.37 392.628 374.37 393.028C374.37 393.428 374.694 393.752 375.093 393.752Z" fill="white" />
    <path d="M588.361 152.724C588.76 152.724 589.084 152.399 589.084 152C589.084 151.6 588.76 151.276 588.361 151.276C587.961 151.276 587.638 151.6 587.638 152C587.638 152.399 587.961 152.724 588.361 152.724Z" fill="white" />
    <path d="M581.132 91.9237C581.531 91.9237 581.855 91.5996 581.855 91.1999C581.855 90.8001 581.531 90.4761 581.132 90.4761C580.732 90.4761 580.409 90.8001 580.409 91.1999C580.409 91.5996 580.732 91.9237 581.132 91.9237Z" fill="white" />
    <path d="M556.552 287.352C556.951 287.352 557.275 287.028 557.275 286.629C557.275 286.229 556.951 285.905 556.552 285.905C556.152 285.905 555.829 286.229 555.829 286.629C555.829 287.028 556.152 287.352 556.552 287.352Z" fill="white" />
    <path d="M517.512 178.781C517.912 178.781 518.235 178.457 518.235 178.057C518.235 177.657 517.912 177.333 517.512 177.333C517.113 177.333 516.79 177.657 516.79 178.057C516.79 178.457 517.113 178.781 517.512 178.781Z" fill="white" />
    <path d="M523.296 76.724C523.696 76.724 524.019 76.3999 524.019 76.0002C524.019 75.6004 523.696 75.2764 523.296 75.2764C522.897 75.2764 522.573 75.6004 522.573 76.0002C522.573 76.3999 522.897 76.724 523.296 76.724Z" fill="white" />
    <path d="M589.084 35.4667C589.483 35.4667 589.807 35.1426 589.807 34.7429C589.807 34.3431 589.483 34.019 589.084 34.019C588.685 34.019 588.361 34.3431 588.361 34.7429C588.361 35.1426 588.685 35.4667 589.084 35.4667Z" fill="white" />
    <path d="M376.539 328.609C376.938 328.609 377.262 328.285 377.262 327.885C377.262 327.486 376.938 327.162 376.539 327.162C376.14 327.162 375.816 327.486 375.816 327.885C375.816 328.285 376.14 328.609 376.539 328.609Z" fill="white" />
    <path d="M438.712 383.619C439.111 383.619 439.435 383.294 439.435 382.895C439.435 382.495 439.111 382.171 438.712 382.171C438.313 382.171 437.989 382.495 437.989 382.895C437.989 383.294 438.313 383.619 438.712 383.619Z" fill="white" />
    <path d="M359.188 274.324C359.588 274.324 359.911 274 359.911 273.6C359.911 273.2 359.588 272.876 359.188 272.876C358.789 272.876 358.465 273.2 358.465 273.6C358.465 274 358.789 274.324 359.188 274.324Z" fill="white" />
    <path d="M377.262 178.781C377.661 178.781 377.985 178.457 377.985 178.057C377.985 177.657 377.661 177.333 377.262 177.333C376.863 177.333 376.539 177.657 376.539 178.057C376.539 178.457 376.863 178.781 377.262 178.781Z" fill="white" />
    <path opacity="0.1" d="M377.262 178.781L462.931 141.816V151.602L377.262 178.781Z" fill="white" />
    <path opacity="0.1" d="M643.304 63.9917V94.3699L502.692 138.985V124.661L643.304 63.9917Z" fill="white" />
    <path opacity="0.1" d="M419.222 285.89L462.931 261.78V265.131C438.488 276.98 420.075 285.499 419.222 285.89Z" fill="white" />
    <path opacity="0.1" d="M503.184 239.581H514.209C510.384 241.557 506.546 243.518 502.692 245.465V239.856L503.184 239.581Z" fill="white" />
    <path opacity="0.1" d="M589.807 191.81C589.474 193.728 586.951 196.449 582.751 199.771H575.37L589.807 191.81Z" fill="white" />
    <path opacity="0.1" d="M643.305 321.632V326.742C605.77 345.496 571.48 361.355 570.316 361.89L643.305 321.632Z" fill="white" />
    <path d="M558.721 328.745C558.72 327.309 558.309 325.904 557.535 324.694C556.762 323.485 555.659 322.523 554.357 321.922L554.588 318.966H538.435L540.313 338.148H553.086L553.256 335.977C554.828 335.531 556.213 334.584 557.199 333.278C558.186 331.973 558.72 330.381 558.721 328.745ZM553.345 334.832L554.24 323.412C555.015 324.178 555.614 325.105 555.995 326.127C556.376 327.149 556.529 328.242 556.444 329.33C556.358 330.418 556.037 331.474 555.502 332.424C554.967 333.374 554.231 334.196 553.345 334.832Z" fill="white" />
    <path d="M541.305 313.132C541.305 313.132 560.405 326.792 554.038 332.255C547.671 337.719 534.938 315.864 534.938 315.864L541.305 313.132Z" fill="#FFB9B9" />
    <path d="M453.08 334.076C453.08 334.076 456.718 379.607 457.628 385.982C458.537 392.356 459.447 449.725 461.266 456.1C463.085 462.474 464.904 477.954 464.904 477.954H475.818C475.818 477.954 474.909 449.725 477.637 438.798C480.366 427.87 479.456 397.82 479.456 397.82C479.456 397.82 484.914 344.093 482.185 336.808C479.456 329.523 453.08 334.076 453.08 334.076Z" fill="#FFB9B9" />
    <path d="M467.632 475.223C467.632 475.223 463.085 477.955 463.085 479.776C463.085 481.597 463.994 486.151 463.085 487.972C462.175 489.793 455.808 497.078 459.447 498.899C463.085 500.721 479.456 498.899 479.456 498.899C479.456 498.899 478.547 486.151 477.637 485.24C476.728 484.329 478.547 475.223 478.547 475.223H467.632Z" fill="black" />
    <path d="M522.609 332.961C522.609 332.961 523.764 378.623 523.528 385.058C523.292 391.492 528.402 448.64 527.261 455.17C526.12 461.701 525.934 477.287 525.934 477.287L515.08 478.434C515.08 478.434 513.025 450.265 509.166 439.685C505.307 429.104 503.061 399.124 503.061 399.124C503.061 399.124 492.001 346.268 493.951 338.736C495.9 331.205 522.609 332.961 522.609 332.961Z" fill="#FFB9B9" />
    <path d="M522.934 474.856C522.934 474.856 527.743 477.095 527.934 478.906C528.125 480.718 527.698 485.341 528.793 487.057C529.889 488.772 536.984 495.348 533.557 497.542C530.13 499.735 513.658 499.644 513.658 499.644C513.658 499.644 513.226 486.871 514.035 485.869C514.844 484.868 512.08 476.003 512.08 476.003L522.934 474.856Z" fill="black" />
    <path d="M463.486 266C463.486 266 455.069 268.189 451.912 305.4C448.756 342.611 437.182 436.733 446.652 443.3C456.121 449.867 484.529 463 485.581 458.622C486.633 454.244 488.738 443.3 488.738 443.3C488.738 443.3 485.581 463 489.79 463C493.998 463 535.032 449.867 533.98 443.3C532.928 436.733 530.824 388.578 530.824 388.578C530.824 388.578 524.511 307.589 518.198 294.456C516.371 290.806 514.952 286.365 514.043 281.447C513.134 276.53 512.756 271.256 512.937 266H463.486Z" fill="black" />
    <path d="M452.171 258.495C452.171 258.495 463.995 255.763 465.814 259.406C467.633 263.048 461.266 268.512 458.537 268.512C455.809 268.512 451.261 265.78 451.261 265.78L452.171 258.495Z" fill="#FFB9B9" />
    <path d="M494.031 179.897C503.073 179.897 510.402 172.559 510.402 163.506C510.402 154.453 503.073 147.115 494.031 147.115C484.989 147.115 477.659 154.453 477.659 163.506C477.659 172.559 484.989 179.897 494.031 179.897Z" fill="#FFB9B9" />
    <path d="M484.14 174.737C484.14 174.737 482.185 189.288 480.366 189.288C478.547 189.288 494.009 197.483 494.009 197.483L505.833 195.662L509.471 190.198C509.471 190.198 503.829 181.424 503.829 174.139C503.829 166.854 484.14 174.737 484.14 174.737Z" fill="#FFB9B9" />
    <path d="M487.642 189.288C487.642 189.288 484.914 187.466 484.004 187.466H476.728C475.818 187.466 463.994 190.198 463.994 190.198L456.718 231.176C456.718 231.176 469.452 252.121 460.356 268.512C460.356 268.512 463.085 266.69 475.818 267.601C488.552 268.512 511.29 271.244 512.2 271.244C513.109 271.244 513.109 263.959 512.2 262.137C511.29 260.316 513.109 261.227 513.109 256.674C513.109 252.12 515.838 243.925 515.838 243.925L528.571 197.483C528.571 197.483 512.2 188.377 509.471 189.288C506.742 190.198 498.557 192.93 496.738 192.019C494.919 191.109 487.642 189.288 487.642 189.288Z" fill="#BFC5C4" />
    <path d="M466.723 191.109L463.995 190.198C463.995 190.198 458.537 189.287 452.171 194.751C445.804 200.215 422.156 216.606 422.156 216.606C422.156 216.606 413.061 223.891 415.789 232.087C418.518 240.282 423.066 260.316 436.709 259.405C436.709 259.405 444.894 270.333 449.442 268.512C453.99 266.69 457.628 259.405 454.899 258.495C452.171 257.584 444.894 248.478 444.894 248.478L453.99 231.176H456.718L466.723 225.712L466.723 191.109Z" fill="#BFC5C4" />
    <path d="M521.295 196.573L528.571 197.483C528.571 197.483 534.938 200.215 534.029 212.053C533.119 223.891 539.486 277.618 536.757 283.993C536.757 283.993 545.852 314.954 544.033 315.864C542.214 316.775 531.3 322.239 532.21 318.596C533.119 314.954 522.205 289.456 522.205 289.456C522.205 289.456 509.471 275.797 514.928 256.674V241.193L521.295 196.573Z" fill="#BFC5C4" />
    <path d="M536.478 178.224C534.449 175.351 531.953 172.84 529.811 170.05C524.874 163.617 523.166 155.045 516.541 150.112C509.985 145.23 499.582 140.426 491.378 140.811C480.566 141.318 471.989 149.716 464.536 157.574C458.84 163.579 453.144 169.584 447.449 175.589C443.328 179.933 439.056 184.545 437.571 190.349C436.143 195.926 437.536 201.918 440.149 207.048C442.865 212.251 446.673 216.803 451.314 220.391C457.034 212.506 465.047 201.618 465.047 201.618L466.912 228.701C476.603 231.916 487.012 232.548 497.224 231.837L500.249 220.733L503.424 231.222C515.627 229.603 527.887 225.384 535.441 215.85C543.793 205.308 544.237 189.21 536.478 178.224Z" fill="black" />
    <path d="M468.332 175.489C466.644 167.004 468.467 158.214 474.136 151.482C484.61 139.045 504.216 138.346 517.929 149.922C523.401 154.489 527.327 160.639 529.17 167.529C527.595 159.903 523.48 153.037 517.5 148.058C503.787 136.483 484.18 137.181 473.707 149.618C467.621 156.845 465.967 166.443 468.332 175.489Z" fill="black" />
    <path d="M436.113 215.512C434.425 207.028 436.249 198.237 441.918 191.505C452.391 179.068 471.998 178.37 485.71 189.945C491.182 194.512 495.109 200.663 496.951 207.553C495.376 199.926 491.262 193.061 485.281 188.081C471.568 176.506 451.961 177.205 441.488 189.642C435.403 196.868 433.748 206.467 436.113 215.512Z" fill="black" />
    <path d="M509.182 170.939C517.781 170.092 526.34 172.773 532.474 179.083C543.806 190.74 542.573 210.344 529.719 222.869C524.641 227.872 518.142 231.179 511.113 232.338C518.848 231.518 526.076 228.094 531.614 222.624C544.467 210.1 545.701 190.496 534.368 178.838C527.784 172.064 518.406 169.472 509.182 170.939Z" fill="black" />
    <path opacity="0.2" d="M549.855 286.922C549.018 286.727 548.143 286.791 547.344 287.106C546.544 287.42 545.859 287.97 545.379 288.682C545.04 289.233 544.796 289.836 544.656 290.467C544.259 292.195 544.339 293.999 544.886 295.685C545.433 297.372 546.428 298.877 547.764 300.042C548.464 300.558 549.129 301.122 549.751 301.731C550.328 302.43 550.729 303.258 550.919 304.144C551.11 305.031 551.085 305.95 550.846 306.825C550.345 308.577 549.3 310.124 547.862 311.242C547.563 311.446 547.312 311.715 547.129 312.028C546.64 313.076 547.901 314.073 548.222 315.184" fill="white" />
  </svg>


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="text-center">

      <h1 className="mt-16">Nothing to see here.</h1>

      <h2 className="my-4">Seems like you lost your way!</h2>

      <div className="my-8 object-contain"><Lost /></div>

      <Link
        to="/"
        className="text-btn py-1 px-3 my-8 border border-current text-pumpkin rounded inline-flex items-center justify-center">
        <span>Take me home</span>
        <svg className="w-2 h-3 ml-2" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.82386e-05 10.7629L0.716129 11.5L6.37866 6.00018L0.71613 0.500001L5.90713e-05 1.23725L4.90306 6.00018L5.82386e-05 10.7629Z"
            fill="#DF5F02" />
        </svg>
      </Link>

    </div>

  </Layout>
)

export default NotFoundPage
